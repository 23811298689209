var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"o-vehicle-insurance py-4"},[_c('v-row',[_c('v-col',{staticClass:"mx-4"}),_c('v-col',{staticClass:"shrink mx-4"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","color":"success"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Dodaj polisę")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.state.dialog),callback:function ($$v) {_vm.$set(_vm.state, "dialog", $$v)},expression:"state.dialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.state.isFormValid),callback:function ($$v) {_vm.$set(_vm.state, "isFormValid", $$v)},expression:"state.isFormValid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Dodawanie polisy")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('m-date-picker',{attrs:{"rules":_vm.rules.endDate,"label":"Data zakończenia*","prepend-icon":"mdi-calendar-cursor","hint":"Wymagane","persistent-hint":"","readonly":"","clearable":""},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","suffix":"zł","min":0,"step":0.01,"label":"Składka*","prepend-icon":"mdi-cash-multiple","hint":"Wymagane","persistent-hint":""},model:{value:(_vm.model.premium),callback:function ($$v) {_vm.$set(_vm.model, "premium", _vm._n($$v))},expression:"model.premium"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":"Załączniki","counter":"","rules":_vm.rules.attachments,"multiple":"","show-size":"","persistent-hint":"","hint":"hint","accept":_vm.fileTypesString},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var file = ref.file;
var index = ref.index;
var multiple = ref.multiple;
var text = ref.text;
return [_c('v-chip',{attrs:{"small":"","label":""}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("mdi-file-outline")]),_c('span',[_vm._v(_vm._s(text))]),_c('v-icon',{attrs:{"right":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.model.attachments.splice(index, 1)}}},[_vm._v("mdi-close")])],1)]}},{key:"message",fn:function(ref){
var key = ref.key;
var message = ref.message;
return [(message === 'hint')?_c('div',{staticClass:"pr-5",staticStyle:{"line-height":"1.75"}},[_vm._v("Użyj klawiszy "),_c('kbd',[_vm._v("Shift")]),_vm._v(" oraz "),_c('kbd',[_vm._v("Ctrl")]),_vm._v(" lub "),_c('kbd',[_vm._v("Command"),_c('v-icon',{attrs:{"right":"","x-small":"","dark":""}},[_vm._v("mdi-apple-keyboard-command")])],1),_vm._v(", aby wybrać wiele plików.")]):_c('div',{staticClass:"pr-5",staticStyle:{"line-height":"1.75"}},[_vm._v(_vm._s(message))])]}}]),model:{value:(_vm.model.attachments),callback:function ($$v) {_vm.$set(_vm.model, "attachments", $$v)},expression:"model.attachments"}})],1)],1)],1),_c('v-card-text',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{staticClass:"ml-4",attrs:{"text":""},on:{"click":function($event){_vm.state.dialog = false}}},[_vm._v("Anuluj")]),_c('v-btn',{staticClass:"ml-4",attrs:{"type":"submit","depressed":"","color":"success"}},[_c('span',[_vm._v("Dodaj")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1)],1)],1)],1),_c('v-data-table',{attrs:{"loading":_vm.state.loading,"headers":_vm.state.headers,"items":_vm.state.items,"options":_vm.state.options,"server-items-length":_vm.state.total,"show-expand":"","single-expand":"","value":_vm.state.expanded,"expanded":_vm.state.expanded,"item-key":"id","mobile-breakpoint":"1904"},on:{"update:options":function($event){return _vm.$set(_vm.state, "options", $event)},"update:value":function($event){return _vm.$set(_vm.state, "expanded", $event)},"update:expanded":function($event){return _vm.$set(_vm.state, "expanded", $event)}},scopedSlots:_vm._u([{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("moment")(item.endDate,'D MMMM YYYY')))]}},{key:"item.premium",fn:function(ref){
var item = ref.item;
return [(item.premium)?_c('span',[_vm._v(_vm._s(new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' }).format(item.premium)))]):_c('v-divider')]}},{key:"item.data-table-expand",fn:function(ref){
var expand = ref.expand;
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{staticClass:"mr-n2",attrs:{"right":"","size":"16"}},[_vm._v(_vm._s(item.attachments && item.attachments.length && (item.attachments.length > 9 ? 'mdi-numeric-9-plus' : ("mdi-numeric-" + (item.attachments.length)) ) || 'mdi-numeric-0'))]),_c('v-icon',{staticClass:"mr-3",attrs:{"left":""}},[_vm._v("mdi-paperclip")])],1),_c('v-menu',{attrs:{"left":"","min-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteInsurance(item.id)}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Usuń")])],1),_c('v-list-item-icon',[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-delete")])],1)],1)],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-0 ma-0",attrs:{"colspan":headers.length}},[_c('v-card-text',{staticClass:"grey lighten-5"},[(item.attachments && item.attachments.length)?_c('o-attachments',{attrs:{"ids":item.attachments.map(function (item) { return item.id; }),"deletable":""},on:{"update":_vm.fetchInsurances}}):_c('v-alert',{staticClass:"ma-0",attrs:{"text":"","type":"info"}},[_vm._v("Brak załączników")])],1),_c('v-divider'),_c('v-row',{staticClass:"ma-0 grey lighten-5"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('label',{attrs:{"for":("file-upload-" + (item.id))}},[_c('v-btn',{attrs:{"depressed":"","block":"","color":"success"},on:{"click":function($event){_vm.$refs[("file-upload-" + (item.id))].click()}}},[_c('span',[_vm._v("Dodaj załącznik")]),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-paperclip")])],1),_c('input',{ref:("file-upload-" + (item.id)),staticClass:"d-none",attrs:{"type":"file","multiple":"","id":("file-upload-" + (item.id))},on:{"input":function (event) { return _vm.handleFileInput(item.id, event); },"change":function (event) { return _vm.handleFileInput(item.id, event); }}})],1)]),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12","md":"8"}},[_c('div',{staticClass:"o-vehicle-insurance__attachment-uploader",on:{"drop":function (event) { return _vm.handleDrop(item.id, event); },"dragover":function (event) { return event.preventDefault(); },"dragenter":function (event) { return _vm.handleDrag(item.id, event, 'enter'); },"dragleave":function (event) { return _vm.handleDrag(item.id, event, 'leave'); }}},[_c('span',[_vm._v("Przeciągnij tutaj plik")])])])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }