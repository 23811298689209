







































































































import useAttachment from "@/use/attachment";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { DataOptions } from "vuetify";

export default defineComponent({
  components: {
    MDatePicker: () => import("@/components/molecules/m-date-picker.vue"),
    OAttachments: () => import("@/components/organisms/o-attachments.vue"),
  },
  setup(_, { root }) {
    const axiosInstance = root?.$store.getters[
      "api/getInstance"
    ] as AxiosInstance;
    const {
      fileTypes,
      fileTypesString,
      truncateFileName,
      uploadAttachments,
      downloadAttachment,
      deleteAttachment,
    } = useAttachment({ root });

    const form = ref<any>(null);

    const insurances = ref<any[]>([]);

    const state = reactive({
      dialog: false,
      loading: false,
      headers: [
        { value: "endDate", text: "Data zakończenia", sortable: false },
        { value: "premium", text: "Składka", sortable: false },
        {
          value: "data-table-expand",
          text: "",
          sortable: false,
          align: "end",
          width: 1,
        },
      ],
      items: computed(() =>
        insurances.value.map((insurance) => ({
          id: insurance.id,
          endDate: insurance.endDate,
          premium: insurance.premium,
          attachments: insurance.attachments,
        }))
      ),
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      total: 0,
      expanded: [],
      uploading: [] as { id: string; file: File }[],
      menu: { endDate: false },
      isFormValid: true,
    });

    const model = reactive<{
      endDate: null | string;
      premium: null | string;
      attachments: File[];
    }>({
      endDate: null,
      premium: null,
      attachments: [],
    });

    const rules = {
      endDate: [(v: string) => !!v || "Podaj datę zakończenia"],
      attachments: [
        (v: File[]) =>
          !v ||
          (v && v.length <= 5) ||
          "Maksymalna ilość plików na raz to 5. Możesz dodać więcej plików po dodaniu serwisu.",
      ],
    };

    const resetModel = () => {
      model.endDate = null;
      model.premium = null;
      model.attachments = [];

      form.value.resetValidation();
    };

    const fetchInsurances = () => {
      const { page, itemsPerPage } = state.options as DataOptions;

      state.loading = true;

      axiosInstance
        .get(`vehicle/${root.$route.params.id}/insurance`, {
          params: { page, itemsPerPage },
        })
        .then(({ data }) => {
          insurances.value = data.insurances;
          state.total = data.total;
        })
        .catch(() => {
          insurances.value = [];
          state.total = 0;
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const addInsurance = async () => {
      const insuranceData: {
        endDate: string;
        premium?: string;
        attachment?: string[];
      } = {
        endDate: model.endDate as string,
        premium: model.premium || undefined,
      };

      if (model.attachments && model.attachments.length) {
        await uploadAttachments(model.attachments)
          .then(({ data }) => {
            insuranceData.attachment = data.attachments.map(
              (attachment: { id: string }) => attachment.id
            );
          })
          .catch((error) => console.log(error));
      }

      axiosInstance
        .post(`vehicle/${root.$route.params.id}/insurance`, insuranceData)
        .then(() => {
          state.dialog = false;
          resetModel();
          fetchInsurances();
        })
        .catch((error) => console.log(error));
    };

    const deleteInsurance = (id: string) => {
      state.loading = true;
      axiosInstance
        .delete(`insurance/${id}`)
        .then(fetchInsurances)
        .catch((error) => console.log(error))
        .finally(() => {
          state.loading = false;
        });
    };

    const onSubmit = async () => {
      await form.value.validate();
      if (state.isFormValid) addInsurance();
    };

    const uploadToExisting = async (id: string, files: File[]) => {
      for (const file of files) {
        state.uploading.push({ id, file });
      }
      uploadAttachments(files).then(({ data }) => {
        axiosInstance
          .post(`insurance/${id}/attachment`, {
            attachment: data.attachments.map((a: { id: string }) => a.id),
          })
          .then(fetchInsurances)
          .finally(() => {
            for (const file of files) {
              state.uploading.splice(state.uploading.indexOf({ id, file }), 1);
            }
          });
      });
    };

    const handleFileInput = (id: string, event: Event) => {
      event.preventDefault();

      let files: File[] = [];

      const input = event.target as HTMLInputElement;
      if (input.files) {
        files = [];
        for (let i = 0; i < input.files.length; i++) {
          const file = input.files[i];
          if (file) files.push(file);
        }
      }

      if (files.length > 0) uploadToExisting(id, files);

      input.value = "";
      if (input.value) {
        input.type = "text";
        input.type = "file";
      }
    };

    const handleDrag = (id: string, event: Event, type: "enter" | "leave") => {
      event.preventDefault();

      if (type === "leave")
        (event.target as HTMLElement)?.classList.remove("hover");
      else (event.target as HTMLElement)?.classList.add("hover");
    };

    const handleDrop = (id: string, event: DragEvent) => {
      event.preventDefault();

      if (event.target)
        (event.target as HTMLElement)?.classList.remove("hover");

      let files: File[] = [];

      if (event.dataTransfer?.items) {
        // Use DataTransferItemList interface to access the file(s)
        files = [];
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          // If dropped items aren't files, reject them
          if (event.dataTransfer.items[i].kind === "file") {
            const file = event.dataTransfer.items[i].getAsFile();
            if (file) files.push(file);
          }
        }
      } else {
        // Use DataTransfer interface to access the file(s)
        files = [];
        const filesLength = event.dataTransfer?.files.length || 0;
        for (let i = 0; i < filesLength; i++) {
          const file = event.dataTransfer?.files[i] as File;
          if (file) files.push(file);
        }
      }

      if (files.length > 0) uploadToExisting(id, files);
    };

    watch(
      () => model,
      () => (form.value as any).validate(),
      { deep: true }
    );

    watch(() => state.options, fetchInsurances, { deep: true });
    onMounted(fetchInsurances);

    return {
      form,
      state,
      model,
      rules,
      fetchInsurances,
      fileTypes,
      fileTypesString,
      truncateFileName,
      downloadAttachment,
      deleteAttachment,
      deleteInsurance,
      onSubmit,
      handleFileInput,
      handleDrag,
      handleDrop,
    };
  },
});
